<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单分类" style="width: 250px">
          <a-select-option v-for="i in 8" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='bussType'>
        <a-select v-model="form.bussType" placeholder="发货方式" style="width: 250px">
          <a-select-option value="">
            发货方式
          </a-select-option>
          <a-select-option value="deliver">
            物流发货
          </a-select-option>
          <a-select-option value="draw">
            自提现货
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='id'>
        <a-input v-model='form.id' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='memberAreaName'>
        <a-input v-model='form.memberAreaName' style="width: 300px" placeholder="分公司简称" />
      </a-form-model-item>
      <a-form-model-item prop='addressUserName'>
        <a-input v-model='form.addressUserName' style="width: 350px" placeholder="收货人" />
      </a-form-model-item>
      <a-form-model-item prop='addressUserMobile'>
        <a-input v-model='form.addressUserMobile' style="width: 350px" placeholder="手机号" />
      </a-form-model-item>
      <a-form-model-item prop='customStatus'>
        <a-select v-model="form.customStatus" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="1">
            待完成
          </a-select-option>
          <a-select-option :value="4">
            已完成
          </a-select-option>
          <a-select-option :value="10">
            待核销
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='delete' @click='handleDeleteAll'>删除</a-button>
        <a-button type='primary' icon='table' @click='exportToExcel'>导出订单</a-button>
        <!-- <a-button type='primary' icon='swap' @click='initialDeliver2'>订单转移</a-button> -->
        <span>物流金额：￥{{dashboard.logisticsAmount}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot='purchaseAgreementUrl' slot-scope='text,record'>
          <template v-if="record.purchaseAgreementStatus==0">
              待签
          </template>
          <template v-else-if="record.purchaseAgreementStatus==1">
              <a @click='showImg(text)'>查看</a>
          </template>
          <template v-else-if="record.purchaseAgreementStatus==-1">
              无
          </template>
        </span>

        <span slot='orderAgreementUrl' slot-scope='text,record'>
            <template v-if="record.orderAgreementStatus==0">
              待签
          </template>
          <template  v-else-if="record.orderAgreementStatus==1">
              <a @click='showImg(text)'>查看</a>
          </template>
          <template  v-else-if="record.orderAgreementStatus==-1">
              无
          </template>
        </span>


        <span slot='id' slot-scope='text, record'>
            <a @click='handleEdit(record)'>
              {{ text }}
            </a>
        </span>
        <span slot='orderAmount' slot-scope='text,record'>
          <template v-if="record.status == 'finished'">
            {{text}}
          </template>
        </span>
        <span slot='bussType' slot-scope='text,record'>
          <template v-if="text == 'deliver'">
            {{record.regionOneName}} {{record.regionTwoName}} {{record.regionThreeName}} {{record.address}}
          </template>
          <template v-else>
            自提现货：{{record.depositoryName}}
          </template>
        </span>
        <span slot='bussType1' slot-scope='text,record'>
          <template v-if="record.bussType == 'deliver'">
            物流发货
          </template>
          <template v-else>
            自提现货
          </template>
        </span>
        <span slot='status' slot-scope='text, record'>
          <template>
            <a-badge dot v-if='text=="paying"' status='processing' :text='"待支付"' />
            <a-badge dot v-else-if='text=="delivering"' status='processing' :text='record.bussType == "draw" ? "待提货" : "待发货"' />
            <a-badge dot v-else-if='text=="receiving"' status='warning' :text='"待收货"' />
            <a-badge dot v-else-if='text=="finished"' status='success' :text='"已完成"' />
            <a-badge dot v-else-if='text=="closed"' status='error' :text='"已取消"' />
            <a-badge dot v-else status='default' :text='"其他"' />
          </template>
        </span>
<!--        <span slot='paymentMethod' slot-scope='text'>-->
<!--          <template>-->
<!--            <a-badge dot v-if='text=="money"' status='processing' :text='"微信"' />-->
<!--            <a-badge dot v-else-if='text=="trans"' status='warning' :text='"转账"' />-->
<!--            <a-badge dot v-else-if='text=="cash"' status='warning' :text='"现金"' />-->
<!--            <a-badge dot v-else status='default' :text='"其他"' />-->
<!--          </template>-->
<!--        </span>-->
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a v-if='record.status=="paying"' @click='handleCancel(record)'>取消订单</a>
              <a v-if='record.bussType=="deliver" && record.status=="delivering"' @click='initialDeliver(record)'>确认发货</a>
              <a v-if='record.bussType=="deliver" && (!record.logisticsProvider ||!record.logisticsAmount ||!record.logisticsNo)' @click='initialDeliver1(record)'>
                    物流金额
<!--                {{!record.logisticsProvider &&!record.logisticsAmount &&!record.logisticsAmount.logisticsNo}}-->
              </a>
<!--              <a v-if='record.bussType=="deliver" && (record.status=="receiving" || record.status=="finished") && !record.logisticsMethod' @click='initialDeliver1(record)'>物流金额</a>-->
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='确认发货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading' :width="800">
      <a-form-model ref='form' :model='deliver.form' :rules='rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='总需发货'>
          <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :data='loadData1'>
            <span slot='stitle' slot-scope='text'>
              <template>
                  <Ellipsis :lines='2' tooltip :length='20'>{{ text }}</Ellipsis>
              </template>
            </span>
          </s-table>
        </a-form-model-item>
        <a-form-model-item label='订单号'>
          {{deliver.form.id}}
        </a-form-model-item>
        <template v-if="deliver.form.address">
          <a-form-model-item label='收货人'>
            {{deliver.form.name}}
          </a-form-model-item>
          <a-form-model-item label='联系电话'>
            {{deliver.form.mobile}}
          </a-form-model-item>
          <a-form-model-item label='详细地址'>
            {{ deliver.form.regionOneName }}
            {{ deliver.form.regionTwoName }}
            {{ deliver.form.regionThreeName }}
            {{ deliver.form.address }}
          </a-form-model-item>
        </template>
<!--        <a-form-model-item label='物流名称' prop='logisticsProvider'>-->
<!--          <a-select v-model="deliver.form.logisticsProvider" placeholder="请选择">-->
<!--            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">-->
<!--              {{item}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label='物流单号' prop='logisticsNo'>-->
<!--          <a-input v-model='deliver.form.logisticsNo' />-->
<!--        </a-form-model-item>-->
        <!-- <a-form-model-item label='物流金额' prop='logisticsAmount'>
          <a-input v-model='deliver.form.logisticsAmount' />
        </a-form-model-item>
        <a-form-model-item label='付费方式' prop='logisticsMethod'>
           <a-radio-group name="radioGroup" v-model="deliver.form.logisticsMethod">
            <a-radio :value="1">
              到付
            </a-radio>
            <a-radio :value="2">
              寄付
            </a-radio>
          </a-radio-group>
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>

    <a-modal title='物流金额' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading' :width="800">
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='物流名称' prop='logisticsProvider'>
          <a-select v-model="deliver1.form.logisticsProvider" placeholder="请选择">
            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='物流单号' prop='logisticsNo'>
          <a-input v-model='deliver1.form.logisticsNo' />
        </a-form-model-item>
        <a-form-model-item label='物流金额' prop='logisticsAmount'>
          <a-input v-model='deliver1.form.logisticsAmount' />
        </a-form-model-item>
        <a-form-model-item label='付费方式' prop='logisticsMethod'>
          <a-radio-group name="radioGroup" v-model="deliver1.form.logisticsMethod">
            <a-radio :value="1">
              到付
            </a-radio>
            <a-radio :value="2">
              寄付
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='订单转移' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading' :width="800">
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='订单号' prop='id'>
          <a-input v-model='deliver2.form.id' />
        </a-form-model-item>
        <a-form-model-item label='被转移手机号' prop='newMobile'>
          <a-input v-model='deliver2.form.newMobile' />
        </a-form-model-item>
        <a-form-model-item label='被转移人姓名' prop='newName'>
          <a-input v-model='deliver2.form.newName' />
        </a-form-model-item>
        <a-form-model-item label='订单转移原因' prop='reason'>
          <a-input v-model='deliver2.form.reason' />
        </a-form-model-item>
        <a-form-model-item label='备注'>  
          转移订单仅变更下单人，名额、业绩、归属商户不变
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='协议预览' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' :width="800"
             :footer="null">
          <img :src="deliver3.imgUrl" width="100%">

    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryMemberOrders, deliverMemberOrder, putMemberOrderAddress, cancelMemberOrder, getMemberOrderAddress, getMemberOrderProductions, deleteMemberOrder, getDepositoryStatistics, putMemberOrderTransfer } from '@/api/member-order'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import moment from 'moment'


const columns = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '出库时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'memberAreaName',
    scopedSlots: { customRender: 'memberAreaName' }
  },
  {
    width: 80,
    title: '订单金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    width: 100,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 100,
    title: '收货人手机号',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
  {
    width: 100,
    title: '购买协议',
    dataIndex: 'purchaseAgreementUrl',
    scopedSlots: { customRender: 'purchaseAgreementUrl' }
  },
  {
    width: 100,
    title: '订货协议',
    dataIndex: 'orderAgreementUrl',
    scopedSlots: { customRender: 'orderAgreementUrl' }
  },
  {
    width: 80,
    title: '发货方式',
    dataIndex: 'bussType1',
    scopedSlots: { customRender: 'bussType1' }
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const exportColums = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '账户名称',
    dataIndex: 'bankAccountName',
    scopedSlots: { customRender: 'bankAccountName' }
  },
  {
    width: 150,
    title: '付款时间',
    dataIndex: 'merchantPaymentTime',
    scopedSlots: { customRender: 'merchantPaymentTime' }
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 150,
    title: '发货方式',
    dataIndex: 'bussType',
    scopedSlots: { customRender: 'bussType' }
  },
  {
    width: 150,
    title: '联系电话',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
  {
    width: 100,
    title: '商品内容',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 100,
    title: '物流金额',
    dataIndex: 'logisticsAmount',
    scopedSlots: { customRender: 'logisticsAmount' }
  },
  {
    width: 150,
    title: '商户负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 150,
    title: '支付方式',
    dataIndex: 'merchantPaymentMethodName',
    scopedSlots: { customRender: 'merchantPaymentMethodName' }
  },
  {
    width: 150,
    title: '录单人',
    dataIndex: 'operatorNickname',
    scopedSlots: { customRender: 'operatorNickname' }
  },
  {
    width: 150,
    title: '实付金额',
    dataIndex: 'merchantAmount',
    scopedSlots: { customRender: 'merchantAmount' }
  },
  {
    width: 150,
    title: '订单留言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' }
  },
]

const columns1 = [
  {
    width: 150,
    title: '产品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '购买数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

export default {
  name: 'InsideStorehouseOrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      dashboard: {},
      selectedRowKeys: [],
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        bussType: "",
        customStatus: 1,
        start: baseStart,
        end: baseEnd,
        merchantOrderStatus: 2,
        depositoryTag: 0,
      },
      loading: true,
      total: 0,
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const form = Object.assign({}, this.form);
        switch (Number(form.customStatus)) {
          case 1:
            form.statusIn = ["delivering"];
            form.depositoryTag = 0;
            delete form.start;
            delete form.end;
            break;
          case 4:
            form.statusIn = [ "receiving", "finished"];
            form.depositoryTag = 1;
            break;
          default:
            delete form.statusIn;
            form.depositoryTag = 0;
            break;
        }
        if (form.customStatus == 10) {
          delete form.statusIn;
          form.depositoryTag = 0;
          form.checkStatus = 1;
        } else {
          delete form.checkStatus
        }
        // debugger;
        this.getDashboard(form);
        let params = Object.assign(parameter, form);
        params.notQueryQuick = 1;//1-不查询 未选购订单，
        return queryMemberOrders(params)
          .then(datum => {
            this.total = datum.total;
            this.$emit("setOrderTitle", this.total);
            return datum;
          })
      },
      rules: {
        logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
        logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
        logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
      },
      houseList: [],
      prodList: [],
      staffList: [],
      deliver: { visible: false,loading: false, form: {} },
      deliver1: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        }, 
      },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          id: [{ required: true, message: '请填写订单号', trigger: 'change' }],
          newMobile: [{ required: true, message: '请填写被转移人手机', trigger: 'change' }],
          newName: [{ required: true, message: '请填写被转移人姓名', trigger: 'change' }],
          reason: [{ required: true, message: '请填写订单转移原因', trigger: 'change' }],
        }, 
      },
      deliver3: { visible: false,loading: false, form: {} },
      columns1,
      loadData1: parameter => {
        let queryParam = {};
        if (this.deliver.form.id) {
          queryParam.order = this.deliver.form.id;
        }
        return getMemberOrderProductions(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
    }
  },
  computed: {
    ...mapGetters(["expressList"])
  },
  created() {},
  methods: {
    moment,
    showImg(url){
        this.deliver3.visible=true;
        this.deliver3.imgUrl = url;
    },

    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteAll() {
      const requestList = [];
      this.selectedRowKeys.forEach(item => {     
         requestList.push(deleteMemberOrder({ id: item }))
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个订单');
        return;
      }
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
      })

    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    handleChange(e) {
      if (e) {
        let msg = this.houseList.find(item => item.id == e)
        if (msg) {
          this.deliver.form.depositoryName = msg.depositoryName;
        }
      } else {
        this.deliver.form.depositoryName = "";
      }
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    exportToExcel () {
      this.columns = exportColums
      this.$nextTick(() => {
        let et = XLSX.utils.table_to_book(document.querySelector('#table'),{ raw: true }); //此处传入table的DOM节点
        let etout = XLSX.write(et, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([etout], {
                type: 'application/octet-stream'
            }), '普通订单.xlsx');   //trade-publish.xlsx 为导出的文件名
        } catch (e) {
            console.log(e, etout) ;
        }
        this.columns = columns
        return etout;
      })
    },
    handleDetail() {
      this.$router.push({ path: '/inside/storehouse/detail'})
    },
    handleEdit(record) {
      this.$router.push({ path: '/inside/storehouse/order', query: { id: record.id } })
    },
    handleCancel(record) {
      cancelMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    initialDeliver2() {
        this.deliver2.form = {};
        this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        if (result) {
          this.deliver2.loading = true;
          putMemberOrderTransfer(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    initialDeliver1(record) {
      // let logisticsProvider =record.logisticsProvider;
        this.deliver1.form = {
          id: record.orderAddressId,
          logisticsMethod: record.logisticsMethod||2,
          logisticsAmount:record.logisticsAmount,
          logisticsNo:record.logisticsNo,
          logisticsProvider:record.logisticsProvider,
        };
        // debugger

      // this.deliver1.form = {
      //   id: record.id,
      //   logisticsMethod: record.logisticsMethod,
      //   logisticsAmount:record.logisticsAmount,
      //   logisticsNo:record.logisticsNo,
      //   logisticsProvider:record.logisticsProvider,
      // };
        this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          let params = Object.assign(this.deliver1.form);
          // debugger;
          putMemberOrderAddress(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initialDeliver(record) {
      getMemberOrderAddress({ order: record.id }).then(result => {

        // this.deliver.form = { id: record.id, address: Object.assign({}, this.deliver.form.address, result)}
        let params = Object.assign({id: record.id}, result);
        // debugger
        this.deliver.form = params
        this.deliver.visible = true
        this.$nextTick(() => {
          this.$refs.table1.refresh(true);
        })
      })
    },
    handleDeliver() {
      this.$refs.form.validate((result) => {
        if (result) {
          this.deliver.loading = true;
          let params = Object.assign(this.deliver.form)
          debugger;
          deliverMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    getDashboard(form) {
      let params = Object.assign(form);
      params.notQueryQuick = 1;//1-不查询 未选购订单，
      getDepositoryStatistics(params).then(result => {
        this.dashboard = result;
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>

